'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from '@whoop/i18n/lang/client';
import LockedBold from 'ui/icons/icons/Various/LockedBold';
import { cltw } from 'ui/utils';
import WhoopLogoDark from 'ui/icons/svg/whoop-logo-dark.svg';
import WhoopLogo from 'ui/icons/svg/whoop-logo.svg';
import WhoopLogoRound from 'ui/icons/svg/whoop-logo-round.svg';
import WhoopLogoRoundWhite from 'ui/icons/svg/whoop-logo-round-white.svg';
import Icon from 'ui/components/Icon/Icon';
import { UnstyledButton } from 'ui';
import { useShallow } from 'zustand/react/shallow';
import { useNavigation } from '@/hooks/navigation';
import { useGetCurrentPage } from '@/hooks/getCurrentPage';
import CountrySelectorWrapper from '@/components/CountrySelectorWrapper/CountrySelectorWrapper';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { env } from 'env';

interface HeaderProps {
  showCountrySelector?: boolean;
  darkMode?: boolean;
  showCart?: boolean;
  cartCounter?: number;
  toggleIsCartOpen?: () => void;
  children?: React.ReactNode;
  showOrderPageV2Header?: boolean;
  skipMemQueryParam?: string;
}

export function Header({
  showCountrySelector = true,
  showCart = false,
  cartCounter,
  toggleIsCartOpen,
  darkMode = false,
  children,
  showOrderPageV2Header,
  skipMemQueryParam = '',
}: HeaderProps): JSX.Element {
  const { t } = useTranslation('header');
  const { t: tCheckout } = useTranslation('checkout');
  const { getLocalizedPath } = useNavigation();
  const currentPage = useGetCurrentPage();
  const { featureFlags } = useSiteStore(
    useShallow((state) => ({
      featureFlags: state.featureFlags,
    })),
  );

  // On landing page, logo links to whoop.com
  // Everywhere else, it takes you to landing page
  const baseJoinUrl = getLocalizedPath('');
  const anchorLink =
    currentPage === 'Join'
      ? 'https://www.whoop.com'
      : `${baseJoinUrl}${
          skipMemQueryParam ? `?membership=${skipMemQueryParam}` : ''
        }`;

  const logo = (
    <>
      <Image
        alt='Whoop Logo'
        className='hidden cursor-pointer align-middle md:block'
        height={25}
        src={darkMode ? WhoopLogoDark : WhoopLogo}
        width={133}
      />
      <Image
        alt='Whoop Logo'
        className={`h-9 w-9 md:hidden ${
          showCountrySelector ? 'mr-[76px]' : ''
        }`}
        src={showOrderPageV2Header ? WhoopLogoRoundWhite : WhoopLogoRound}
      />
    </>
  );

  const handleResetSite = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.location.href = env.NEXT_PUBLIC_JF_REPLATFORM_URL;
  };

  return (
    <div className='z-header h-16 w-full shadow-sm'>
      <div
        className={cltw(
          showOrderPageV2Header ? 'bg-black md:bg-white' : 'bg-white',
          'relative flex h-full w-full items-center justify-between px-4 py-2 lg:px-8 lg:py-2',
        )}
      >
        {/* If the user is leaving the receipt page we need to clear the state and treat the site like its a new session
         To do this, we can use an <a/> tag since this will hard reload the site where as the <Link/> tag can read from the cache
         If it exists*/}
        {currentPage === 'Receipt' ? (
          <a
            href={anchorLink}
            rel='noopener'
            target=''
            onClick={handleResetSite}
          >
            {logo}
          </a>
        ) : (
          <Link
            href={anchorLink}
            rel='noopener'
            target={anchorLink.includes('whoop') ? '_blank' : ''}
          >
            {logo}
          </Link>
        )}
        {children}
        {showCountrySelector ? <CountrySelectorWrapper /> : null}
        {showOrderPageV2Header && !showCart && !showCountrySelector ? (
          <h2 className='flex items-center pr-[6px] text-sm font-semibold uppercase tracking-wide text-white md:hidden'>
            <LockedBold fill='white' height={12} />
            {tCheckout('checkout')}
          </h2>
        ) : null}
        {showCart ? (
          <UnstyledButton
            aria-label={t('viewCart')}
            className='mt-md mr-sm'
            onClick={toggleIsCartOpen}
            type='button'
          >
            <Icon alt='cart' size='medium' variant='shopping_cart' />
            <div className='bg-accent h-md w-md relative -top-10 left-2/3 rounded-full border-2 border-white bg-black text-center text-xs font-bold text-white'>
              {cartCounter}
            </div>
          </UnstyledButton>
        ) : null}
        {/* This is to maintain correct justification of items. */}
        {!showCart && !showCountrySelector && !showOrderPageV2Header ? (
          <div />
        ) : null}
      </div>
    </div>
  );
}

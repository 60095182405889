import clsx from 'clsx';
import React, { useEffect } from 'react';
import CloseIcon from '../../icons/close';
import { UnstyledButton } from '../ButtonV2/Button';
import { Sheet, SheetContent } from '../Sheet/Sheet';
import type { SheetOverlay } from '../Sheet/Sheet';
import ArrowLeft from '../../icons/icons/Navigation/ArrowLeft';

export type DrawerPosition = 'right' | 'left' | 'bottom' | 'top';

interface IDrawer {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onClose?: () => void;
  children?: React.ReactNode;
  position?: DrawerPosition;
  title?: string;
  drawerId?: string;
  blurBackground?: boolean;
  onBackClick?: () => void;
  className?: string;
}

function Drawer({
  isOpen,
  setIsOpen,
  onClose,
  children,
  position = 'right',
  title,
  drawerId,
  blurBackground = false,
  onBackClick,
  className,
  ...props
}: IDrawer): React.ReactNode {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent): void => {
      if (event.code === 'Escape') {
        if (onClose) {
          onClose();
        }
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('keydown', handleEscapeKey);
      return () => document.removeEventListener('keydown', handleEscapeKey);
    }
    document.body.style.overflow = 'auto';
  }, [isOpen]);

  const handleClose = (): void => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };

  // Focus on trigger button when drawer is closed.
  // Requires passing a unique 'drawerId' prop to Drawer, and setting it as the
  // 'data-drawer-trigger' value on the trigger button
  const focusOnTrigger = (): void => {
    const triggerButton = document.querySelector(
      `[data-drawer-trigger="${drawerId}"]`,
    );
    if (triggerButton instanceof HTMLElement) {
      triggerButton.focus();
    }
  };

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent
        overlayProps={
          {
            className: clsx(
              'z-drawerBackdrop flex fixed bottom-0 left-0 right-0 top-0 overscroll-contain',
              blurBackground ? 'backdrop-blur-sm' : 'bg-black/50',
            ),
            'data-testid': 'drawer-close-button',
          } as React.ComponentPropsWithoutRef<typeof SheetOverlay>
        }
        className={clsx(
          'z-drawer h-dvh max-h-dvh fixed flex w-full flex-col overflow-x-auto overflow-y-scroll overscroll-contain bg-white px-5 pb-5 pt-5 text-black shadow-2xl transition duration-300 ease-in-out sm:w-auto',
          className,
        )}
        onCloseAutoFocus={focusOnTrigger}
        side={position}
        {...props}
      >
        <div
          className={clsx(
            'flex w-full flex-row items-center pb-6',
            title ? 'justify-between' : 'justify-end',
          )}
        >
          <div className='flex flex-row'>
            {onBackClick ? (
              <UnstyledButton
                className='pr-3'
                data-testid='drawer-back-button'
                onClick={onBackClick}
              >
                <ArrowLeft fill='black' height={24} width={24} />
              </UnstyledButton>
            ) : null}
            <h2 className='text-xl font-semibold uppercase tracking-wider'>
              {title}
            </h2>
          </div>
          <UnstyledButton
            aria-label='close-button'
            className={clsx(title ? 'ml-4' : 'mr-0')}
            onClick={handleClose}
            type='button'
          >
            <CloseIcon height={24} pathStroke='#808080' width={24} />
          </UnstyledButton>
        </div>
        <div className='flex-1'>{children}</div>
      </SheetContent>
    </Sheet>
  );
}

export default Drawer;

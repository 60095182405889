export function getUTCDateWithoutOffset(date: Date): Date {
  const utcYear = date.getUTCFullYear();
  const utcMonth = date.getUTCMonth();
  const utcDate = date.getUTCDate();
  const utcHours = date.getUTCHours();
  const utcMinutes = date.getUTCMinutes();
  const utcSeconds = date.getUTCSeconds();
  const utcMilliseconds = date.getUTCMilliseconds();

  return new Date(
    Date.UTC(
      utcYear,
      utcMonth,
      utcDate,
      utcHours,
      utcMinutes,
      utcSeconds,
      utcMilliseconds,
    ),
  );
}

import { formatDate, formatPrice } from '@whoop/i18n';
import type { Currency, Region, Language } from '@whoop/i18n';
import { getApplicationSchemeForCurrency, type PromoInfo } from 'ui';
import type { SiteWidePromoContent } from 'types/siteWidePromoTypes';

interface IReplaceTemplateData {
  template: string;
  replacement: string;
  variable: '{{price}}' | '{{date}}' | '{{annualPrice}}' | '{{24mPrice}}'; // {{percent}}
}

const replaceTemplateVariable = ({ template, variable, replacement }): string =>
  template.replaceAll(variable, replacement);

const replaceTemplatePrice = ({
  template,
  replacement,
}: Omit<IReplaceTemplateData, 'variable'>): string =>
  replaceTemplateVariable({ template, variable: '{{price}}', replacement });

const replaceTemplateDate = ({
  template,
  replacement,
}: Omit<IReplaceTemplateData, 'variable'>): string =>
  replaceTemplateVariable({ template, variable: '{{date}}', replacement });

const replaceTemplateDiscount = ({
  template,
  replacement,
}: Omit<IReplaceTemplateData, 'variable'>): string =>
  replaceTemplateVariable({ template, variable: '{{discount}}', replacement });

const replaceTemplateAnnualPrice = ({
  template,
  replacement,
}: Omit<IReplaceTemplateData, 'variable'>): string =>
  replaceTemplateVariable({
    template,
    variable: '{{annualPrice}}',
    replacement,
  });

const replaceTemplateTwentyfourMonthPrice = ({
  template,
  replacement,
}: Omit<IReplaceTemplateData, 'variable'>): string =>
  replaceTemplateVariable({
    template,
    variable: '{{24mPrice}}',
    replacement,
  });

const replaceTemplateMembershipPrices = ({
  template,
  annualPrice,
  twentyfourMonthPrice,
}: {
  template: string;
  annualPrice?: string;
  twentyfourMonthPrice?: string;
}): string => {
  let result = template;

  if (annualPrice) {
    result = replaceTemplateAnnualPrice({
      template: result,
      replacement: annualPrice,
    });
  }

  if (twentyfourMonthPrice) {
    result = replaceTemplateTwentyfourMonthPrice({
      template: result,
      replacement: twentyfourMonthPrice,
    });
  }

  return result;
};

interface IGetFormattedDateForBanner {
  dateString: string;
  language: Language;
}
const getFormattedDateForBanner = ({
  dateString,
  language,
}: IGetFormattedDateForBanner): string => {
  // Replace hyphens with slashes and remove the time component
  // https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
  const safeDateString = dateString.replace(/-/g, '/').replace(/T.+/, '');
  const safeDate = new Date(safeDateString);
  return formatDate(
    safeDate,
    language,
    {
      month: 'short',
      day: 'numeric',
    },
    'short',
  );
};

interface IGetReplacementDate {
  siteWidePromoContent: SiteWidePromoContent;
  language: Language;
}

const getReplacementDate = ({
  siteWidePromoContent,
  language,
}: IGetReplacementDate): string => {
  // Replace {{date}} with localized date
  if (!siteWidePromoContent.promoEndDate) return '';
  return getFormattedDateForBanner({
    dateString: siteWidePromoContent.promoEndDate,
    language,
  });
};

interface IGetReplacmentPrice {
  promo: PromoInfo;
  currency: Currency;
  language: Language;
  region: Region;
}

const getReplacementPrice = ({
  promo,
  currency,
  language,
  region,
}: IGetReplacmentPrice): string => {
  // Replace {{price}} with localized price
  if (promo.promo_code.application_schemes) {
    const validPromo = getApplicationSchemeForCurrency(
      promo.promo_code.application_schemes,
      currency,
    );
    if (validPromo?.promo_type === 'amount_off') {
      return formatPrice(validPromo.amount, currency, {
        country: region,
        language,
        showCents: false,
      });
    }
    return '';
  }
  return '';
};

interface IGetReplacedString {
  template: string;
  replacementDate: string;
  replacementPrice: string;
}

const getReplacedString = ({
  template,
  replacementDate,
  replacementPrice,
}: IGetReplacedString) => {
  let returnValue = template;
  if (returnValue && replacementDate) {
    returnValue = replaceTemplateDate({
      template: returnValue,
      replacement: replacementDate,
    });
  }
  if (returnValue && replacementPrice) {
    returnValue = replaceTemplatePrice({
      template: returnValue,
      replacement: replacementPrice,
    });
  }
  return returnValue;
};

interface IFormatSitewidePromoTemplates {
  language: Language;
  region: Region;
  currency: Currency;
  promo: PromoInfo;
  siteWidePromoContent: SiteWidePromoContent;
}

function formatSitewidePromoTemplates({
  siteWidePromoContent,
  language,
  region,
  currency,
  promo,
}: IFormatSitewidePromoTemplates): SiteWidePromoContent {
  /*
   * Contentful authors may add template variables such as {{date}} or
   * {{price}} which will get replaced with localized versions below
   * These variables can exist in an field that allows text so we loop through each field
   */
  const replacementDate = getReplacementDate({
    siteWidePromoContent,
    language,
  });
  const replacementPrice = getReplacementPrice({
    promo,
    currency,
    language,
    region,
  });

  return Object.keys(siteWidePromoContent).reduce(
    (acc, field) => {
      const template = acc[field];
      acc[field] = getReplacedString({
        template,
        replacementDate,
        replacementPrice,
      });
      return acc;
    },
    {
      ...siteWidePromoContent,
    },
  );
}

export {
  replaceTemplatePrice,
  replaceTemplateDate,
  replaceTemplateDiscount,
  replaceTemplateMembershipPrices,
  getFormattedDateForBanner,
  formatSitewidePromoTemplates,
  replaceTemplateVariable,
  getReplacementDate,
  getReplacementPrice,
  getReplacedString,
};

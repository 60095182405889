import {
  MIDDLE_EAST_AFRICA_REGION_CODES,
  ASIA_PACIFIC_REGION_CODES,
  EUROPE_UNITED_KINGDOM_REGION_CODES,
  NORTH_AMERICA_REGION_CODES,
  SOUTH_AMERICA_REGION_CODES,
} from '../types/countrySelectorRegions';
import { getLanguagesForCountry } from './languageHelpers';
import { getCountriesForRegion } from './addressHelpers';

export const getSupportedCountriesAndLanguagesForRegion = (
  regionCodes: string[],
) => {
  return regionCodes
    .map((regionCode) =>
      getCountriesForRegion(regionCode).map((country) => {
        return {
          countryCode: country.alpha2,
          languagesCodes: getLanguagesForCountry(country.alpha2),
        };
      }),
    )
    .flat();
};

export const getSupportedCountriesAndLanguagesByRegion = () => {
  return [
    {
      regionName: 'North America',
      countries: getSupportedCountriesAndLanguagesForRegion(
        NORTH_AMERICA_REGION_CODES,
      ),
    },
    {
      regionName: 'Europe & United Kingdom',
      countries: getSupportedCountriesAndLanguagesForRegion(
        EUROPE_UNITED_KINGDOM_REGION_CODES,
      ),
    },
    {
      regionName: 'Middle East & Africa',
      countries: getSupportedCountriesAndLanguagesForRegion(
        MIDDLE_EAST_AFRICA_REGION_CODES,
      ),
    },
    {
      regionName: 'Asia Pacific',
      countries: getSupportedCountriesAndLanguagesForRegion(
        ASIA_PACIFIC_REGION_CODES,
      ),
    },
    {
      regionName: 'South America',
      countries: getSupportedCountriesAndLanguagesForRegion(
        SOUTH_AMERICA_REGION_CODES,
      ),
    },
  ];
};
